import OrderCompleteComponent
    from "../../components/order/OrderCompleteComponent";

function OrderComplete() {

    return (
        <OrderCompleteComponent/>
    )
}

export default OrderComplete;